import React from 'react';
import Link from 'next/link';
import PropTypes from 'prop-types';
import IncentiveModal from '@/components/surveys/IncentiveModal';
import { surveyType } from '@/types';
import { shouldShowIncentiveModal } from '@/utilities/survey';
import { useLogLinkClick } from '@separate/hooks/analytics';
import { useGetUrl } from '@separate/hooks/getUrl';
import { logEvent, PANELIST_PRODUCT_ID, REWARDS_ENGAGEMENT_STARTED } from '@separate/utilities/analytics';
import { compact } from '@separate/utilities/object';
import styles from "./surveyLink.module.scss";

export default function SurveyLink(props) {
  const { survey, user, children, surveyPosition } = props;
  const [showIncentiveModal, setShowIncentiveModal] = React.useState(false);
  const [updatedUrl, setUpdatedUrl] = React.useState('');
  const surveyLink = survey.is_study ? "#/" : `/surveys/${survey.id}`;
  const { canonicalUrl } = useGetUrl();

  React.useEffect(() => {
    if (!survey.url) return;

    const replacePidUrl = survey.url.replaceAll("{{pid}}", user?.pid);
    const urlObject = new URL(replacePidUrl);
    const allParams = Object.fromEntries(urlObject.searchParams.entries());

    for (const property in user) {
      if (allParams.hasOwnProperty(property)) {
        urlObject.searchParams.set(property, user[property]);
      }
    }

    setUpdatedUrl(urlObject.toString());

  }, [survey.url, user]);

  const type = survey.is_study ? 'study' : 'survey';
  const destinationUrl = survey.is_study ? updatedUrl : `/surveys/${survey.id}`;
  const eventProperties = {
    href: destinationUrl,
    position: surveyPosition,
    activity: 'rewards_engagement_start',
    step: undefined,
    type,
    isExternal: survey.is_study,
  };
  const { logLinkClick } = useLogLinkClick(eventProperties);

  function handleClick() {
    // This will eventually be phased out
    logLinkClick();

    // Global click tracker somehow is not working,
    // so I had to resort to a click handler
    logEvent(REWARDS_ENGAGEMENT_STARTED, compact(
      {
        type: type,
        id: survey.id,
        location: canonicalUrl,
        position: surveyPosition,
        product_id: PANELIST_PRODUCT_ID,
        service_utilization: "true",
      }
    ));

    if (survey.is_study) {
      if (shouldShowIncentiveModal({ survey, user })) {
        setShowIncentiveModal(true);
      } else {
        window.open(updatedUrl, "_blank");
      }
    }
  }

  return (
    <>
      <Link href={surveyLink}>
        <a key={survey.id} onClick={handleClick} className={styles.link}>
          {children}
        </a>
      </Link>
      <IncentiveModal
        show={showIncentiveModal}
        onHide={() => setShowIncentiveModal(false)}
        user={user}
        inline
      />
    </>
  );
}

SurveyLink.propTypes = {
  survey: surveyType,
  user: PropTypes.object,
  children: PropTypes.node.isRequired,
  surveyPosition: PropTypes.string,
};
