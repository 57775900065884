import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import Button from '@/components/Button';
import LinkButton from '@/components/LinkButton';
import Modal from '@/components/Modal';
import { CloseCircle, MobileLucid } from '@/icons';
import { useTranslation } from '@separate/hooks/translation';
import { extractDataProps } from '@separate/utilities/object';
import styles from './lucidModal.module.scss';

export default function LucidModal({ show, onHide, href, ...extraProps }) {
  const { t } = useTranslation();

  return (
    <Modal
      show={show}
      onHide={onHide}
      close={false}
      className={styles.modal}
      contentClassName={cx(styles.modalContent, 'my-4')}
      bodyClassName={styles.modalBody}
      fullScreen={true}
      centered={false}
    >
      <Button iconButton className={cx(styles.closeButton, 'd-block')} onClick={onHide}>
        <CloseCircle />
      </Button>
      <div className='d-flex align-items-center justify-content-center my-4'>
        <MobileLucid />
      </div>
      <div className={cx(styles.header, 'my-3')}>
        <h2>{t('surveys.lucid_modal.title')}</h2>
        <p>{t('surveys.lucid_modal.subtitle')}</p>
      </div>
      <div className={styles.listContent}>
        <ul>
          <li>{t('surveys.lucid_modal.content.text_one')}</li>
          <li>{t('surveys.lucid_modal.content.text_two')}</li>
        </ul>
      </div>
      <LinkButton
        href={href}
        target="_blank"
        rel="noopener noreferrer"
        className={cx(styles.linkCta, 'd-block')}
        onClick={onHide}
        {...extractDataProps(extraProps)}
      >
        {t('surveys.lucid_modal.cta')}
      </LinkButton>
    </Modal>
  );
}

LucidModal.propTypes = {
  show: PropTypes.bool,
  onHide: PropTypes.func,
  href: PropTypes.string,
};
