import React from 'react';
import cx from 'classnames';
import Link from 'next/link';
import { useRouter } from 'next/router';
import PropTypes from 'prop-types';
import Card from 'react-bootstrap/Card';
import { hrefType } from '@/types';
import styles from './ctawithfooter.module.scss';

export default function CTAWithFooter({
  background,
  children,
  className,
  bodyClassName,
  color,
  footer,
  footerBg,
  footerFg,
  href,
  onClick,
  img,
  ...extraProps
}) {
  const router = useRouter();

  function handleClick() {
    if (onClick) {
      onClick();
    } else {
      router.push(href);
    }
  }

  const CTACard = () => {
    return (
      <Card
        className={cx(styles.standard, styles.ctacard, className)}
        style={{ background: background, color: color }}
        onClick={handleClick}
        {...extraProps}
      >
        <Card.Body
          style={{ backgroundImage: img ? `url(${img})` : undefined }}
          className={cx(styles.backgroundImage, bodyClassName, "d-flex align-items-center justify-content-center p-0")}
        >
          {children}
        </Card.Body>
        {footer && (
          <Card.Footer
            className={cx(styles.ctaFooter)}
            style={{ background: footerBg, color: footerFg }}
          >
            {footer}
          </Card.Footer>
        )}
      </Card>
    );
  };

  return (
    <>
      {href ? (
        <Link href={href}>
          <a className={styles.noDecoration}>
            <CTACard />
          </a>
        </Link>
      ) : (
        <CTACard />
      )}
    </>
  );
}

CTAWithFooter.propTypes = {
  background: PropTypes.string,
  children: PropTypes.element,
  className: PropTypes.string,
  bodyClassName: PropTypes.string,
  color: PropTypes.string,
  footer: PropTypes.element,
  footerBg: PropTypes.string,
  footerFg: PropTypes.string,
  href: hrefType,
  img: PropTypes.string,
  onClick: PropTypes.func,
};
