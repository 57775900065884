import find from 'lodash/find';
import { COMPLETE_YOUR_PROFILE_1_ID } from '@/constants/surveys';

export const SURVEY_QUESTION_TYPES = {
  selectOne: 1,
  selectMultiple: 2,
  rankOrder: 3,
  numericFreeform: 6,
  selectOneImage: 10,
  selectMultipleImage: 14,
  welcome: 11,
  completed: 12,
  declined: 13,
  textFreeform: 15,
  directions: 17,
  essay: 18,
  userForm: 19,
  starRating: 20,
  profile: 22,
  productistic: 23,
  redirect: 302,
};

export function otherAnswerEnabled(question) {
  return question.other_answer_id === 1;
}

export function translateSurveyText(item, locale = 'es') {
  if (!item) return '';

  // TODO: clean this up when the back end no longer returns english_translation/text for legacy surveys
  return locale === "en" ? item.english_translation || item.text_en : item.text || item.text_es;
}

export function surveyTitle(survey, locale = 'es') {
  if (!survey) return '';

  return locale === "en" ? survey.title : survey.title_es;
}

export function currentQuestion(surveyData, options = {}) {
  const { inline } = options;
  const { survey, survey_complete } = surveyData;

  function welcomeQuestionId() {
    return find(survey.survey_questions, {
      survey_question_type_id: SURVEY_QUESTION_TYPES.welcome
    }).id;
  }

  const currentQuestionId =
    survey_complete.current_survey_question_id ||
    (inline ? survey.first_question_id : welcomeQuestionId());

  return find(survey.survey_questions, {
    id: currentQuestionId
  });
}

export function shouldShowIncentiveModal({ survey, user }) {
  if (user && !user.is_panelist && !user.is_podercard_user) {
    if (survey.id !== COMPLETE_YOUR_PROFILE_1_ID && survey.incentive > 0) {
      return true;
    }
  }

  return false;
}
