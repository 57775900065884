import { useQuery, useQueryClient } from 'react-query';
import { getLucidUrl, getSurveyCenterData } from '@/api/surveyCenter';
import { useCurrentUserSession } from '@separate/hooks/session';

const SURVEY_CENTER_CACHE_KEY = 'survey-center';
const LUCID_CACHE_KEY = 'open-lucid';

function currentSurveyCenterDataKey(idToken) {
  return [SURVEY_CENTER_CACHE_KEY, idToken];
}

export function useGetSurveyCenterData() {
  const { idToken } = useCurrentUserSession();

  const result = useQuery({
    queryKey: currentSurveyCenterDataKey(idToken),
    queryFn: getSurveyCenterData,
  });
  const { isLoading, data } = result;

  return { isLoading, data };
}

export function useInvalidateSurveyCenterData() {
  const { idToken } = useCurrentUserSession();
  const queryClient = useQueryClient();
  return () => queryClient.invalidateQueries(currentSurveyCenterDataKey(idToken));
}

export function useGetLucidUrl(lang) {
  const result = useQuery({
    queryKey: [LUCID_CACHE_KEY, lang],
    queryFn: () => getLucidUrl(lang),
  });

  const { isLoading, data } = result;

  return { isLoading, url: data && data.url ? data.url : '' };
}
