import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import SurveyCard from '@/components/cards/SurveyCard';
import SurveyCardList from '@/components/cards/SurveyCardList';
import SurveyCardMobile from '@/components/cards/SurveyCardMobile';
import SurveyPartners from '@/components/surveys/SurveyPartners';
import SurveyPartnersWithoutImage from '@/components/surveys/SurveyPartnersWithoutImage';
import { useGetLucidUrl } from '@/hooks/surveyCenter';
import { localeType } from '@/types';
import { LUCID, PANELIST_PRODUCT_ID, REWARDS_ENGAGEMENT_STARTED, REWARDS_OPPORTUNITY_VIEWED } from '@separate/constants/analytics';
import { useGetUrl } from '@separate/hooks/getUrl';
import { logEvent } from '@separate/utilities/analytics';
import { isFeatureEnabled } from '@separate/utilities/features';
import styles from './surveyList.module.scss';

export default function SurveyList(props) {
  const {
    className,
    list,
    user,
    locale,
    vertical,
    isOnDesktop,
    fullSurveyList,
    ...extraProps
  } = props;

  const { url } = useGetLucidUrl(locale);
  const { canonicalUrl } = useGetUrl();

  const CardComponent = vertical ? SurveyCardList : !isOnDesktop ? SurveyCardMobile : SurveyCard;
  const surveyClassName = isOnDesktop ? styles.grid : styles.vertCarousel;
  const surveyLists = fullSurveyList ? list : isOnDesktop ? list?.slice(0,6) : list?.slice(0,1);
  const allowNewRewardsEnrollment = isFeatureEnabled('allow_new_rewards_enrollment_flow');
  const ExternalSurveys = allowNewRewardsEnrollment ? SurveyPartners : SurveyPartnersWithoutImage;

  const handleClick = () => {
    logEvent(REWARDS_OPPORTUNITY_VIEWED, {
      type: "start_lucid",
      product_id: PANELIST_PRODUCT_ID,
      service_utilization: "true",
    });
    logEvent(REWARDS_ENGAGEMENT_STARTED, {
      type: LUCID,
      location: canonicalUrl,
      position: "0",
      service_utilization: "true",
      product_id: PANELIST_PRODUCT_ID,
    });
  };

  return (
    <>
      <div className={surveyClassName} {...extraProps}>
        {(!isOnDesktop && !vertical) && (
          <ExternalSurveys
            url={url}
            className={cx(styles.surveyPartner)}
            onClick={handleClick}
          />
        )}
        {surveyLists?.map((survey, index) => (
          <CardComponent
            index={index}
            key={survey.id}
            className={cx(styles.listCard, styles[`survey-${index + 1}`], styles.survey)}
            surveyCardClassName={styles.surveyCard}
            survey={survey}
            user={user}
            locale={locale}
          />
        ))}
      </div>
    </>
  );
}

SurveyList.propTypes = {
  className: PropTypes.string,
  list: PropTypes.array,
  user: PropTypes.object,
  locale: localeType,
  vertical: PropTypes.bool,
  isOnDesktop: PropTypes.bool,
  fullSurveyList: PropTypes.bool,
};
